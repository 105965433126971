import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  margin: 6rem 0;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 1rem;
    justify-content: center;
    li {
      margin: 0;
      padding: 0;
      a {
        color: var(--muted);
        text-decoration: none;
        &:hover {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }
  .right {
    text-align: right;
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <ul>
        <li>Quick links: </li>
        <li>
          <a href="https://www.mlb.com/">MLB</a>
        </li>
        <li>
          <a href="https://www.espn.com/mlb/">ESPN</a>
        </li>
        <li>
          <a href="https://apnews.com/hub/baseball">AP</a>
        </li>
        <li>
          <a href="https://www.cbssports.com/mlb/">CBS</a>
        </li>
        <li>
          <a href="https://www.nbcsports.com/mlb">NBC</a>
        </li>
        <li>
          <a href="https://www.foxsports.com/mlb">FOX</a>
        </li>
        <li>
          <a href="https://www.japantimes.co.jp/sports/mlb/">Japan Times</a>
        </li>
        <li>
          <a href="https://english.kyodonews.net/news/sports">Kyodo</a>
        </li>
        <li>
          <a href="http://www.asahi.com/ajw/sports/">Asahi</a>
        </li>
        <li>
          <a href="https://mainichi.jp/english/sports/">Mainichi</a>
        </li>
        <li>
          <a href="https://www.asahi.com/sports/baseball/mlb/">朝日</a>
        </li>
        <li>
          <a href="https://www.nikkansports.com/baseball/mlb/">日刊</a>
        </li>
        <li>
          <a href="https://www.sanspo.com/baseball/mlb/mlb.html">サンスポ</a>
        </li>
        <li>
          <a href="https://www.sponichi.co.jp/baseball/tokusyu/mlb/">
            スポニチ
          </a>
        </li>
        <li>
          <a href="https://hochi.news/baseball/mlb/">報知</a>
        </li>
        <li>
          <a href="https://www.chunichi.co.jp/chuspo/baseball/mlb">中スポ</a>
        </li>
        <li>
          <a href="https://www.tokyo-sports.co.jp/list/baseball/mlb">東スポ</a>
        </li>
        <li>
          <a href="https://www.daily.co.jp/mlb/">デイリー</a>
        </li>
        <li>
          <a href="https://full-count.jp/category/mlb/">Full-Count</a>
        </li>
        <li>
          <a href="https://baseball.yahoo.co.jp/mlb/">Sportsnavi</a>
        </li>
      </ul>
      <p>&copy; Japanese Ball Players 1999 - {new Date().getFullYear()}</p>
      <p className="right">
        Project by <a href="https://twitter.com/daigofuji">Daigo Fujiwara</a> ⚾
        💻 🤓, checkout{' '}
        <a href="http://www.baseballhackday.com/">Baseball Hack Day</a>
      </p>
    </FooterStyles>
  );
}
