import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const ContentStyle = styled.div`
  margin: 0 auto;
  background: white;
  padding: 2rem;
  max-width: 1200px;
`;

export default function Layout({ children }) {
  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;900&family=Press+Start+2P&display=swap"
        rel="stylesheet"
      />
      <GlobalStyles />
      <Typography />
      <ContentStyle>
        <Nav />
        {children}
        <Footer />
      </ContentStyle>
    </>
  );
}
