import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.nav`
  margin-bottom: 3rem;
  h1 {
    font-size: 0.625rem;
    font-size: clamp(
      0.625rem,
      -0.5416666666666667rem + 4.912280701754386vw,
      2.375rem
    );
    text-align: center;
    margin: 0 auto 1rem;
    color: var(--red);
  }

  h2 {
    font-family: var(--body-family);
    text-align: center;
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.32rem + 2.56vw, 1.6rem);
    margin-bottom: 2rem;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
    a {
      font-size: 1rem;
      text-decoration: none;
      &:hover {
        color: var(--red);
      }
    }
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <h1>JapaneseBallPlayers.com</h1>
      <h2>MLB Players from Japan ⚾ 日本人メジャーリーガー</h2>
      <ul>
        <li>
          <Link to="/">Currently Active （現役）</Link>
        </li>
        <li>
          <Link to="/players/">All Players（歴史）</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
