import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  :root {
    --body-family: 'Noto Sans JP', Ariel, 'Open Sans', 'Helvetica Neue', sans-serif;
    --hed-family: 'Press Start 2P', Trebuchet, Tahoma, Arial, sans-serif;
  }
  html {
    font-family: var(--body-family);
    color: var(--black);
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: var(--hed-family);
    font-weight: normal;
    margin: 0;
    font-smooth: never;
  }
  nav {
    font-family: var(--hed-family);
    font-weight: normal;
  }
  .noto {
    font-family: var(--body-family);
  }
  strong .noto,  strong.noto, .noto.bold {
    font-family: var(--body-family);
    font-weight: 800;
  }

  a {
    color: var(--black);
    text-decoration-color: var(--red);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }  

  .center {
    text-align: center;
  }

  .bold, strong {
    font-weight: 800;
  }
`;

export default Typography;
